import React, { useContext, useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import {
  CssBaseline,
  IconButton,
  InputAdornment,
  Paper,
  Box,
  Grid,
  FormControl,
  Typography,
  Snackbar,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../components/TextField";
import axios from "../api/axios";
import AuthContext from "../context/AuthProvider";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AppButton from "../components/AppButton";
import { API_URL } from "../utils/api";

const backgroundImage = require("./Assets/Group 48.png");
const Image = require("./Assets/Group 47.png");
const logo = require("./Assets/Group 109.png");
const text = require("./Assets/Group 81.png");
const theme = createTheme();

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const { state } = useLocation();

  const [loginToast, setLoginToast] = useState(false);
  useEffect(() => {
    const userSignedup = localStorage.getItem("signup");
    if (userSignedup) {
      setLoginToast(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await localStorage.clear();
    try {
      const response = await axios.post(
        API_URL + "users/login",
        JSON.stringify({ email, password }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const userData = response?.data?.data;
      setIsLoading(false);
      if (userData.user_type === "merchant") {
        const token = response?.data?.data?.token;
        const phoneNumber = response?.data?.data?.other_details?.phone_no;
        await localStorage.setItem("phoneNumber", phoneNumber);
        await localStorage.setItem("token", response?.data?.data?.token);
        setAuth(email, password, token);
        setEmail("");
        setPassword("");
        setsuccessMessage("");
        navigate("/dashboard");
      }

      // if (!emailValidator(email))
      //   return setErrMsg("Please enter valid email id");
    } catch (err) {
      setIsLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg(err.response?.data?.data);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          // sx={{ height: "100vh", overflow: "hidden" }}
        >
          <CssBaseline />
          <Grid
            item
            sm={4}
            md={7}
            sx={{
              background:
                " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%);",
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
          >
            <img
              style={{
                height: "200px",
                position: "absolute",
                top: "35%",
                left: "20%",
              }}
              src={logo}
              alt=""
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 10,
                mx: 4,
                pt: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                variant="h3"
                fontSize="40"
                fontWeight="500"
                sx={{ color: "rgb(247,66,49)" }}
              >
                Login
              </Typography>
              <Typography
                component="h5"
                sx={{ my: 1, mb: 3, fontWeight: "500", fontSize: "13px" }}
              >
                Log in into your Locus Merchant Portal
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 1 }}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextInput
                      required={true}
                      icon={
                        <InputAdornment position="start">
                          <MailOutlineIcon sx={{ color: "rgb(247,66,49)" }} />
                        </InputAdornment>
                      }
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      inputProps={{
                        pattern: "[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ my: "10px", mt: 3, width: "100%" }}>
                    <TextInput
                      icon={
                        <InputAdornment position="start">
                          <LockOutlinedIcon sx={{ color: "rgb(247,66,49)" }} />
                        </InputAdornment>
                      }
                      label="Password"
                      required={true}
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography
                    color={"red"}
                    className={errMsg ? "errmsg" : "offscreen"}
                  >
                    {errMsg}
                  </Typography>

                  {isOnline ? null : (
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={isToastOpen}
                      autoHideDuration={4000}
                      onClose={() => setIsToastOpen(false)}
                      message="No Internet Connection"
                    />
                  )}

                  {loginToast && (
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginToast}
                      autoHideDuration={6000}
                      onClose={() => setLoginToast(false)}
                      message="Registration successfull. Please login now."
                    />
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    <AppButton
                      style={{ mt: 3, mb: 2, px: 5 }}
                      type="submit"
                      title="Login"
                      disabled={isLoading || !isOnline ? true : false}
                    />

                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      sx={{ color: "rgb(247,66,49)", cursor: "pointer" }}
                      onClick={() => navigate("/forgetPassword")}
                    >
                      Forgot password?
                    </Typography>

                    <Box
                      sx={{
                        mt: "80px",
                        alignSelf: "center",
                      }}
                    >
                      <Link
                        fontWeight="bold"
                        style={{
                          color: "black",
                          cursor: "pointer",
                        }}
                        to="register"
                      >
                        Create a Locus Merchant Account
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}
