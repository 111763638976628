import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  CssBaseline,
  Box,
  List,
  Typography,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  TablePagination,
  Snackbar,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { API_URL } from "../utils/api";
import ListItems from "./ListItems";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
  },
}));

const mdTheme = createTheme();

export default function Branches() {
  const [data, setData] = useState([]);
  const [paginatedInfo, setPaginatedInfo] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [limit, setLimit] = useState(8);

  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    if (isOnline) {
      axios(API_URL + "merchant/branches/branch_with_offers?limit=8&offset=0", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          setData(res.data.data);
          setPaginatedInfo(res.data);
        })
        .catch((error) => console.error(error));
    }
  }, [isOnline]);

  const gotoManageBranch = (id) => {
    navigate(`/branchDetails/${id}`);
  };
  const gotoBaristapage = () => navigate("/barista");
  const gotoBranchProfilepage = () => navigate("/branchProfile");

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const offsetData = Number(newPage) * 8;
    axios(
      API_URL +
        `merchant/branches/branch_with_offers?limit=${limit}&offset=${offsetData}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        setData(res.data.data);
        setPaginatedInfo(res.data);
      })
      .catch((error) => console.error(error));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  return (
    <div>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer variant="permanent">
            <List component="nav">
              <ListItems />
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => theme.palette.mode === "white",

              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Box
              mx={3}
              my={4}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  fontSize="xl2"
                  fontWeight="500"
                  gutterBottom
                >
                  Locations
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "15px", color: "rgb(149,158,176)" }}
                >
                  Manage your locations here
                </Typography>
              </Box>
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: "20px",
                    backgroundColor: "rgb(247,66,49)",
                    height: 40,
                    boxShadow: "none",
                    textTransform: "none",
                    borderRadius: 2,
                    ":hover": {
                      bgcolor: "rgb(247,66,49)",
                    },
                  }}
                  onClick={gotoBaristapage}
                  disabled={isOnline ? false : true}
                >
                  + Add Employee
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: "20px",
                    mx: "10px",
                    backgroundColor: "rgb(247,66,49)",
                    height: 40,
                    boxShadow: "none",
                    textTransform: "none",
                    borderRadius: 2,
                    ":hover": {
                      bgcolor: "rgb(247,66,49)",
                    },
                  }}
                  onClick={gotoBranchProfilepage}
                  disabled={isOnline ? false : true}
                >
                  + Add Location
                </Button>
              </Box>
            </Box>
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TableContainer
                    sx={{
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "5px",
                    }}
                  >
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "#9fa2b4" }}>
                            Location Name
                          </TableCell>
                          <TableCell sx={{ color: "#9fa2b4" }}>
                            Address
                          </TableCell>
                          <TableCell sx={{ color: "#9fa2b4" }}>
                            Phone Number
                          </TableCell>
                          <TableCell sx={{ color: "#9fa2b4" }}>
                            Total Employees{" "}
                          </TableCell>
                          <TableCell sx={{ color: "#9fa2b4" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                              No Records found
                            </TableCell>
                          </TableRow>
                        )}
                        {(data || []).map((row) => (
                          <TableRow
                            key={row.id}
                            onClick={() => gotoManageBranch(row?.id)}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <TableCell>{row?.branch_name}</TableCell>
                            <TableCell>{row?.branch_address || "NA"}</TableCell>
                            <TableCell>{row?.branch_phone || "NA"}</TableCell>
                            <TableCell sx={{ fontWeight: "800" }}>
                              {row?.total_barista}
                            </TableCell>

                            <TableCell>
                              <MoreVertIcon color="disabled" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {data.length > 0 && (
                      <TablePagination
                        rowsPerPageOptions={[8]}
                        component="div"
                        count={paginatedInfo?.totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </TableContainer>
                </Grid>
              </Grid>
              {isOnline ? null : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={isToastOpen}
                  autoHideDuration={4000}
                  onClose={() => setIsToastOpen(false)}
                  message="No Internet Connection"
                />
              )}
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}
