import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ForgetPassword from "./pages/ForgetPassword";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import VerifyOtp from "./pages/VerifyOtp";
import Branches from "./pages/Branches";
import Profile from "./pages/Profile";
import Barista from "./pages/Barista";
import BranchProfile from "./pages/BranchProfile";
import ManageBranch from "./pages/ManageBranch";
// import BaristaDetails from "./pages/BaristaDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Signup />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/otpVerify" element={<VerifyOtp />} />
      <Route path="/branches" element={<Branches />} />
      <Route path="/branchDetails/:id" element={<ManageBranch />} />
      {/* <Route path="/baristaDetails" element={<BaristaDetails />} /> */}
      <Route path="/profile" element={<Profile />} />
      <Route path="/branchProfile" element={<BranchProfile />} />
      <Route path="/barista" element={<Barista />} />
    </Routes>
  );
}

export default App;
