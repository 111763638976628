import React from "react";
// import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
// import { Box } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";

// const StyledForm = styled("form")(({ theme }) => ({
//   "& .MuiTextField-root": {
//     margin: theme.spacing(1)
//   }
// }));
// const StyledTextField = styled(TextField)`

// `;

export default function MultilineTextFields(props) {
  return (
    <React.Fragment>
      <FormLabel
        id="demo"
        sx={{
          color: "black",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        {props?.label}
      </FormLabel>
      <TextField
        id="outlined-textarea"
        multiline
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        sx={{
          resize: "none",
          border: "none",
          "& fieldset": { border: "none" },
          backgroundColor: "rgb(255,225,222)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          borderRadius: "8px",
          textDecorationStyle: "none",
          height: "100px",
        }}
      />
    </React.Fragment>
  );
}
