import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  CssBaseline,
  Box,
  FormControl,
  IconButton,
  List,
  Typography,
  InputAdornment,
  Container,
  Button,
  Grid,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { API_URL } from "../utils/api";
import ListItems from "./ListItems";
import TextInput from "../components/TextField";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
  },
}));
const mdTheme = createTheme();

export default function Barista() {
  const [form, setForm] = useState({
    email: "",
    password: "",
    name: "",
    branch_id: "",
  });

  const [errMsg, setErrMsg] = useState("");

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    axios(API_URL + "merchant/branches/branch_with_offers?limit=8&offset=0", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => setData(res.data.data))
      .catch((error) => console.error(error));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      ...form,
      is_merchant: "No",
      is_admin: "No",
      is_barista: "Yes",
    };
    setLoading(true);
    try {
      const response = await axios.post(
        API_URL + "branches/baristas/add",
        formData,
        {
          headers: {
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      navigate(-1);
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg(err.response?.data.data);
      } else if (err.response?.status === 401) {
        // setErrMsg("Unauthorized");
        localStorage.clear();
        navigate("/");
      } else {
        // setErrMsg("Api not working");
      }
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const gotoBranchpage = () => navigate("/branches");
  return (
    <Box>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer variant="permanent">
            <List component="nav">
              <ListItems />
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => theme.palette.mode === "white",

              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Box mx={3} my={4}>
              <Typography
                variant="h5"
                fontSize="xl2"
                fontWeight="500"
                display="block"
                gutterBottom
              >
                Employee
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "15px", color: "rgb(149,158,176)" }}
              >
                Add new Employee
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Container
                maxWidth="lg"
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    borderTop: "1px solid #e0e0e0",
                    borderRight: "1px solid #e0e0e0",
                    borderLeft: "1px solid #e0e0e0",
                    borderBottom: "1px solid #e0e0e0",
                    borderRadius: "5px",
                  }}
                >
                  <Box sx={{ px: 2 }}>
                    <Typography
                      variant="h6"
                      fontSize="xl2"
                      fontWeight="lg"
                      display="block"
                      gutterBottom
                    >
                      Add Employee details
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl sx={{ mx: "20px", width: "45%" }}>
                      <TextInput
                        label="Employee Email"
                        required
                        value={form.email}
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                        inputProps={{
                          pattern: "[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ mx: "20px", width: "45%" }}>
                      <TextInput
                        label="Employee Name"
                        value={form.name}
                        onChange={(e) =>
                          setForm({ ...form, name: e.target.value.trimStart() })
                        }
                        required
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box>
                    {/* <MultipleSelectPlaceholder
                      label="Branch"
                      value={form.branch_id}
                      onChange={(e) =>
                        setForm({ ...form, branch_id: e.target.value })
                      }
                    /> */}
                    <FormControl sx={{ mx: "20px", my: "10px", width: "45%" }}>
                      <FormLabel
                        id="demo"
                        sx={{
                          color: "black",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Location
                      </FormLabel>

                      <Select
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },

                          backgroundColor: "rgb(255,225,222)",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                          borderRadius: "8px",
                        }}
                        displayEmpty
                        value={form.branch_id}
                        onChange={(e) =>
                          setForm({ ...form, branch_id: e.target.value })
                        }
                        input={<OutlinedInput />}
                        // MenuProps={MenuProps}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        required
                      >
                        {data.map((branches) => (
                          <MenuItem
                            key={branches}
                            value={branches?.id}
                            // style={getStyles(branches, branchName, theme)}
                          >
                            {branches?.branch_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ mx: "20px", my: "10px", width: "45%" }}>
                      <TextInput
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={form.password}
                        required
                        inputProps={{
                          maxLength: 20,
                        }}
                        onChange={(e) =>
                          setForm({ ...form, password: e.target.value })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  {/* value={form.phone_no}
                        onChange={(e) =>
                          setForm({ ...form, phone_no: e.target.value })
                        }
                        value={form.date_of_birth}
                        onChange={(e)=>setForm({...form,date_of_birth:e.target.value})} */}

                  <Typography color="red">{errMsg}</Typography>
                  <Box
                    sx={{
                      "& button": { m: 1 },
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      margin: "20px",
                    }}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{
                          backgroundColor: "rgb(125,136,158)",
                          height: 40,
                          boxShadow: "none",
                          textTransform: "none",
                          borderRadius: 2,
                          ":hover": {
                            bgcolor: "rgb(125,136,158)",
                          },
                        }}
                        onClick={gotoBranchpage}
                        type="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          backgroundColor: "rgb(247,66,49)",
                          height: 40,
                          boxShadow: "none",
                          textTransform: "none",
                          borderRadius: 2,
                          ":hover": {
                            bgcolor: "rgb(247,66,49)",
                          },
                        }}
                        disabled={isLoading || !isOnline ? true : false}
                      >
                        + Add
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </form>

            {isOnline ? null : (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={isToastOpen}
                autoHideDuration={4000}
                onClose={() => setIsToastOpen(false)}
                message="No Internet Connection"
              />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
