import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  FormControl,
  List,
  Box,
  Grid,
  Container,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import TextInput from "../components/TextField";
import MultilineTextFields from "../components/TextArea";
import ListItems from "./ListItems";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { API_URL } from "../utils/api";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
  },
}));

const mdTheme = createTheme();
// const API_URL = "http://13.238.161.52:4000/api/v1/";
export default function BranchProfile() {
  const [form, setForm] = useState({
    branch_name: "",
    branch_phone: "",
    branch_address: "",
    offer_when_brought: "",
    offer_get_free: "",
  });

  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const gotoBranchpage = () => navigate("/branches");

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (form.offer_get_free < form.offer_when_brought) {
      try {
        const response = await axios.post(
          API_URL + "merchant/branches/add",
          form,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setIsLoading(false);
        navigate(-1);
      } catch (err) {
        setIsLoading(false);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          // setErrMsg("Unauthorized");
          localStorage.clear();
          navigate("/");
        } else {
          setErrMsg("Api not working");
        }
      }
    } else {
      setIsLoading(false);
      if (form.offer_get_free == 0 || form.offer_when_brought == 0) {
        setErrMsg("Value should not be 0");
      } else {
        setErrMsg("Will get free should be less that When Bought");
      }
    }
  };

  const onNumChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setForm({
        ...form,
        offer_get_free: Number(e.target.value),
      });
    }
  };
  const onNumChangeBought = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setForm({
        ...form,
        offer_when_brought: Number(e.target.value),
      });
    }
  };

  const onPhoneNumberChange = (e) => {
    const numb = e.target.value;
    if (numb.length < 11 && !isNaN(numb)) {
      setForm({ ...form, branch_phone: e.target.value });
    }
  };

  return (
    <React.Fragment>
      {isOnline ? null : (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isToastOpen}
          autoHideDuration={4000}
          onClose={() => setIsToastOpen(false)}
          message="No Internet Connection"
        />
      )}
      <Box>
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <Drawer variant="permanent">
              <List component="nav">
                <ListItems />
              </List>
            </Drawer>

            <Box
              component="main"
              sx={{
                backgroundColor: (theme) => theme.palette.mode === "white",

                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Box mx={3} my={4}>
                <Typography
                  variant="h5"
                  fontSize="xl2"
                  fontWeight="500"
                  display="block"
                  gutterBottom
                >
                  Locations
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "15px", color: "rgb(149,158,176)" }}
                >
                  Add new Locations
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={{
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "5px",
                    }}
                  >
                    <Box sx={{ px: 2, mt: 1 }}>
                      <Typography
                        variant="h6"
                        fontSize="xl2"
                        fontWeight="lg"
                        display="block"
                        gutterBottom
                      >
                        Add Location details
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <FormControl sx={{ mx: "20px", width: "45%" }}>
                        <TextInput
                          required
                          label=" Location Name"
                          value={form.branch_name}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              branch_name: e.target.value.trimStart(),
                            })
                          }
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                      </FormControl>
                      <FormControl sx={{ mx: "20px", width: "45%" }}>
                        <TextInput
                          label="Location Phone Number"
                          value={form.branch_phone}
                          onChange={(e) => onPhoneNumberChange(e)}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </FormControl>
                    </Box>

                    <Box>
                      <FormControl
                        sx={{ mx: "20px", my: "20px", width: "45%" }}
                      >
                        <MultilineTextFields
                          label="Location Address"
                          value={form.branch_address}
                          onChange={(e) =>
                            setForm({ ...form, branch_address: e.target.value })
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl sx={{ mx: "20px", width: "40%" }}>
                        <TextInput
                          label="Buy"
                          name="offer_when_brought"
                          value={form.offer_when_brought}
                          onChange={(e) => onNumChangeBought(e)}
                          required={true}
                        />
                      </FormControl>
                      <FormControl sx={{ mx: "20px", width: "40%" }}>
                        <TextInput
                          label="Get"
                          name="offer_get_free"
                          value={form.offer_get_free}
                          onChange={(e) => onNumChange(e)}
                          required={true}
                        />
                      </FormControl>
                    </Box>
                    <Typography color="red">{errMsg}</Typography>
                    <Box
                      sx={{
                        "& button": { m: 1 },
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        margin: "20px",
                      }}
                    >
                      <Box>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            backgroundColor: "rgb(125,136,158)",
                            height: 40,
                            boxShadow: "none",
                            textTransform: "none",
                            borderRadius: 2,
                            ":hover": {
                              bgcolor: "rgb(125,136,158)",
                            },
                          }}
                          onClick={gotoBranchpage}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            backgroundColor: "rgb(247,66,49)",
                            height: 40,
                            boxShadow: "none",
                            textTransform: "none",
                            borderRadius: 2,
                            ":hover": {
                              bgcolor: "rgb(247,66,49)",
                            },
                          }}
                          type="submit"
                          disabled={isLoading || !isOnline ? true : false}
                        >
                          + Add
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Container>
              </form>
            </Box>
          </Box>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
}
