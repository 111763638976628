import React from "react";
import { Button } from "@mui/material";

export default function AppButton(props) {
  return (
    <Button
      variant="contained"
      type={props.type}
      sx={{
        ...props.style,
        boxShadow: "none",
        textTransform: "none",
        borderRadius: 2,
        backgroundColor: "rgb(247,66,49)",
        width: "35%",
        height: 60,
        ":hover": {
          bgcolor: "rgb(247,66,49)",
        },
      }}
      disabled={props.disabled}
    >
      {props.title}
    </Button>
  );
}
