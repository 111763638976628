import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Grid,
  Box,
  List,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  TablePagination,
  Snackbar,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import ListItems from "./ListItems";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import { API_URL } from "../utils/api";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
  },
}));
const mdTheme = createTheme();

export default function ManageBranch() {
  const [data, setData] = useState([]);
  const { id } = useParams();

  const [paginatedInfo, setPaginatedInfo] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [limit, setLimit] = useState(8);

  const [page, setPage] = React.useState(0);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    axios
      .post(
        API_URL + `merchant/branches/baristas_based_on_branch?limit=8&offset=8`,
        { branch_id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setData(res.data?.data);
        setPaginatedInfo(res.data);
      })
      .catch((error) => console.error(error));
  }, [id]);

  // const offsetData = Number(newPage) * limit;

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const offsetData = (Number(newPage) + 1) * limit;

    axios
      .post(
        API_URL +
          `merchant/branches/baristas_based_on_branch?limit=${limit}&offset=${offsetData}`,
        { branch_id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => setData(res.data?.data))
      .catch((error) => console.error(error));
    // axios(
    //   API_URL +
    //     `merchant/branches/branch_with_offers?limit=${limit}&offset=${offsetData}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   }
    // )
    //   .then((res) => {
    //     setData(res.data.data);
    //     setPaginatedInfo(res.data);
    //   })
    //   .catch((error) => console.error(error));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const navigate = useNavigate();
  const gotoBaristaDetails = () => navigate("/barista");
  const gotoBranchpage = () => navigate("/branches");

  return (
    <div>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer variant="permanent">
            <List component="nav">
              <ListItems />
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => theme.palette.mode === "white",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Box
              mx={3}
              my={4}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  fontSize="xl2"
                  fontWeight="500"
                  gutterBottom
                >
                  Employee
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "15px", color: "rgb(149,158,176)" }}
                >
                  Manage your Employee here
                </Typography>
              </Box>
            </Box>
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TableContainer
                    sx={{
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mx: 2,
                            flex: "1 1 100%",
                            fontSize: "15px",
                          }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          {/* Main Town Branch */}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ mt: 1, display: "flex", flexDirection: "row" }}
                      >
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            backgroundColor: "rgb(125,136,158)",
                            boxShadow: "none",
                            textTransform: "none",
                            borderRadius: 2,

                            height: 60,
                            ":hover": {
                              bgcolor: "rgb(125,136,158)",
                            },
                          }}
                          onClick={gotoBranchpage}
                          type="submit"
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            mx: "10px",
                            boxShadow: "none",
                            textTransform: "none",
                            borderRadius: 2,
                            backgroundColor: "rgb(247,66,49)",
                            height: 60,
                            ":hover": {
                              bgcolor: "rgb(247,66,49)",
                            },
                          }}
                          onClick={gotoBaristaDetails}
                        >
                          + Add Employee
                        </Button>
                      </Box>
                    </Box>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "#9fa2b4" }}>
                            Employee Email
                          </TableCell>
                          <TableCell sx={{ color: "#9fa2b4" }}>Name</TableCell>
                          <TableCell sx={{ color: "#9fa2b4" }}>
                            Password
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                              No Records found
                            </TableCell>
                          </TableRow>
                        )}
                        {data.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{row?.user_info?.email}</TableCell>
                            <TableCell>{row?.user_info?.name}</TableCell>
                            <TableCell>********</TableCell>

                            <TableCell>
                              <MoreVertIcon color="disabled" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {data?.length > 0 && (
                      <TablePagination
                        rowsPerPageOptions={[8]}
                        component="div"
                        count={paginatedInfo?.totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </TableContainer>
                  {isOnline ? null : (
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={isToastOpen}
                      autoHideDuration={4000}
                      onClose={() => setIsToastOpen(false)}
                      message="No Internet Connection"
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}
