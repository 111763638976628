import * as React from "react";
import { useEffect, useState } from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import "../style.css";

import logo from "./Assets/Group 109.png";

import { useLocation, useNavigate } from "react-router-dom";

import merchantIcon from "./Assets/merchantDash.svg";
import dashboardIcon from "./Assets/dashboard.svg";
import logoutIcon from "./Assets/logout.svg";
import profileIcon from "./Assets/profile.svg";

export default function ListItems() {
  const navigate = useNavigate();
  const location = useLocation();

  const gotoDashboardPage = () => {
    navigate("/dashboard");
  };

  const gotoBranchesPage = () => {
    navigate("/branches");
  };

  const gotoProfilePage = () => {
    navigate("/profile");
  };

  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const gotoLoginPage = () => {
    localStorage.removeItem("token");
    setLogout(true);
    navigate("/");
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="" />
        </Box>

        <Box mt={6}>
          <ListItemButton
            onClick={gotoDashboardPage}
            sx={
              location.pathname.includes("dashboard")
                ? { backgroundColor: "rgb(247,66,49)" }
                : {}
            }
          >
            <ListItemIcon sx={{ color: "#d1dbed" }}>
              <img
                src={dashboardIcon}
                style={{ height: 20, width: 20, color: "#d1dbed" }}
                alt=""
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{ color: "#d1dbed" }}
            ></ListItemText>
          </ListItemButton>

          <ListItemButton
            onClick={gotoBranchesPage}
            sx={
              location.pathname.includes("branches")
                ? { backgroundColor: "rgb(247,66,49)" }
                : {}
            }
          >
            <ListItemIcon sx={{ color: "#d1dbed" }}>
              <img
                src={merchantIcon}
                style={{ height: 20, width: 20, color: "#d1dbed" }}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary="Locations" sx={{ color: "#d1dbed" }} />
          </ListItemButton>

          <ListItemButton
            onClick={gotoProfilePage}
            sx={
              location.pathname.includes("profile")
                ? { backgroundColor: "rgb(247,66,49)" }
                : {}
            }
          >
            <ListItemIcon sx={{ color: "#d1dbed" }}>
              <img
                src={profileIcon}
                style={{ height: 20, width: 20, color: "#d1dbed" }}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary="Profile" sx={{ color: "#d1dbed" }} />
          </ListItemButton>
        </Box>

        <Box sx={{ mt: "50%", color: "#d1dbed" }}>
          <ListItemButton
            onClick={gotoLoginPage}
            sx={
              location.pathname.includes("adminUsers")
                ? { backgroundColor: "rgb(247,66,49)" }
                : {}
            }
          >
            <ListItemIcon sx={{ color: "#d1dbed" }}>
              <img
                src={logoutIcon}
                style={{ height: 20, width: 20, color: "#d1dbed" }}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </Box>
      </Box>
    </React.Fragment>
  );
}
