import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  Box,
  Grid,
  InputAdornment,
  Typography,
  FormControl,
  CssBaseline,
  Snackbar,
} from "@mui/material";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import WifiCalling3OutlinedIcon from "@mui/icons-material/WifiCalling3Outlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextInput from "../components/TextField";
import axios from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
// import { phonenumberValidator } from "../components/regexValidator";
import { API_URL } from "../utils/api";
// testbus@gmail.com
const backgroundImage = require("./Assets/Group 48.png");
const Image = require("./Assets/Group 47.png");
const logo = require("./Assets/Group 109.png");
const text = require("./Assets/Group 81.png");

const theme = createTheme();

export default function Signup() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone_no: "",
    password: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (form.password === confirmPassword) {
      setIsLoading(true);
      const formData = {
        ...form,
        is_merchant: "Yes",
        is_admin: "No",
        is_barista: "No",
        is_active: 0,
      };
      setErrMsg("");
      // navigate(-1);
      try {
        const response = await axios.post(
          API_URL + "users/register",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        if (response.data.status_code === 1000 && response.data.status) {
          setMessage("Registration Successfull!");
          localStorage.setItem("signup", true);
          setTimeout(() => {
            setIsLoading(false);
            navigate(-1);
          }, 2000);
        }
      } catch (err) {
        setIsLoading(false);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg(err.response?.data?.data);
        } else {
          setErrMsg("Something went wrong!");
        }
      }
    } else {
      setIsLoading(false);
      setErrMsg("Password and Confirm password is not matching");
    }
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setConfirmShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // const gotoDashboard = () => navigate("/dashboard");
  // const gotoVerificationPage = () => navigate("/forgetPassword");

  const onPhoneNumberChange = (e) => {
    const numb = e.target.value;
    if (numb.length < 11 && !isNaN(numb)) {
      setForm({ ...form, phone_no: e.target.value });
    }
  };

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "100vh", overflow: "hidden" }}
        >
          <CssBaseline />
          <Grid
            item
            sm={4}
            md={7}
            sx={{
              background:
                " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%);",
              display: { xs: "none", sm: "block", md: "block", lg: "block" },
            }}
          >
            <img
              style={{
                height: "200px",
                position: "absolute",
                top: "35%",
                left: "20%",
              }}
              src={logo}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 4,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                variant="h3"
                fontSize="40"
                fontWeight="500"
                sx={{ color: "rgb(247,66,49)" }}
              >
                Sign Up
              </Typography>
              <Typography
                component="h5"
                sx={{ my: 1, fontSize: "13px", fontWeight: "500" }}
              >
                Create your Merchant Account
              </Typography>

              <form onSubmit={handleSubmit}>
                <Box>
                  <FormControl sx={{ my: "5px", width: "100%" }}>
                    <TextInput
                      icon={
                        <InputAdornment position="start">
                          <StoreOutlinedIcon sx={{ color: "rgb(247,66,49)" }} />
                        </InputAdornment>
                      }
                      required
                      label="Business Name"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ my: "5px", width: "100%" }}>
                    <TextInput
                      icon={
                        <InputAdornment position="start">
                          <MailOutlineIcon sx={{ color: "rgb(247,66,49)" }} />
                        </InputAdornment>
                      }
                      required
                      label=" Business Email"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      inputProps={{
                        pattern: "[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ my: "5px", width: "100%" }}>
                    <TextInput
                      icon={
                        <InputAdornment position="start">
                          <WifiCalling3OutlinedIcon
                            sx={{ color: "rgb(247,66,49)" }}
                          />
                        </InputAdornment>
                      }
                      label="Business Phone Number"
                      value={form.phone_no}
                      inputProps={{
                        maxLength: 10,
                      }}
                      onChange={(e) => onPhoneNumberChange(e)}
                    />
                  </FormControl>
                  <FormControl sx={{ my: "5px", width: "100%" }}>
                    <TextInput
                      icon={
                        <InputAdornment position="start">
                          <LockOutlinedIcon sx={{ color: "rgb(247,66,49)" }} />
                        </InputAdornment>
                      }
                      required
                      label="Password"
                      value={form.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      inputProps={{
                        maxLength: 30,
                      }}
                      type={showConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <FormControl sx={{ my: "5px", width: "100%" }}>
                    <TextInput
                      icon={
                        <InputAdornment position="start">
                          <LockOutlinedIcon sx={{ color: "rgb(247,66,49)" }} />
                        </InputAdornment>
                      }
                      label="Confirm Password"
                      value={confirmPassword}
                      inputProps={{
                        maxLength: 30,
                      }}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography sx={{ color: "red" }}>{errMsg}</Typography>
                  <Typography sx={{ color: "green" }}>{message}</Typography>

                  {isOnline ? null : (
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={isToastOpen}
                      autoHideDuration={4000}
                      onClose={() => setIsToastOpen(false)}
                      message="No Internet Connection"
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "rgb(247,66,49)",
                        textTransform: "none",
                        boxShadow: "none",
                        borderRadius: 2,
                        width: "35%",
                        height: 60,
                        ":hover": {
                          bgcolor: "rgb(247,66,49)",
                        },
                      }}
                      value="submit"
                      disabled={isLoading || !isOnline ? true : false}
                    >
                      Sign Up
                    </Button>

                    <Box
                      sx={{
                        color: "black",
                        alignSelf: "center",
                        textDecoration: "none",
                      }}
                    >
                      Already have an account ?{" "}
                      <Link
                        fontWeight="bold"
                        style={{
                          color: "black",
                        }}
                        to=".."
                      >
                        Login
                      </Link>
                    </Box>

                    {/* <Link
                      href="#"
                      variant="body2"
                      sx={{
                        color: "black",
                        alignSelf: "center",
                        fontWeight: "700",
                        textDecoration: "none",
                      }}
                    >
                      Already have an account{" "}
                      <Typography
                        href="/"
                        sx={{
                          color: "rgb(247,66,49)",
                          fontWeight: "700",
                          textDecoration: "none",
                        }}
                        onClick={() => navigate(-1)}
                      >
                       
                      </Typography>
                    </Link> */}
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
}
