import React, { useContext, useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Box,
  List,
  Typography,
  Container,
  Grid,
  Paper,
  ListItemIcon,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

import ListItems from "./ListItems";
import SalesData from "./SalesData";
import Title from "../components/Title";
import AuthContext from "../context/AuthProvider";
import axios from "axios";
import coffeeIcon from "./Assets/coffeeDash.svg";
import customerIcon from "./Assets/customer.svg";
import { API_URL } from "../utils/api";
const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: " linear-gradient(168.2deg, #F74231 10.77%, #280A07 103.87%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
  },
}));

const mdTheme = createTheme();

export default function Dashboard() {
  const { auth } = useContext(AuthContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios(API_URL + "merchant/branches/dashboard/stats", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer variant="permanent">
            <List component="nav">
              <ListItems />
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => theme.palette.mode === "white",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Box mx={3} my={4}>
              <Typography
                variant="h4"
                fontSize="xl2"
                fontWeight="500"
                display="block"
              >
                Welcome Back!
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "15px", color: "rgb(149,158,176)" }}
              >
                Track Your Business Analytics here
              </Typography>
            </Box>

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      minWidth: 290,
                      height: 150,
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      ":hover": {
                        borderColor: "rgb(247,66,49)",
                      },
                    }}
                  >
                    <Title color="#9FA2B4">Items Sold</Title>

                    <Box
                      sx={{
                        dispaly: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          // ":hover": {
                          //   color: "rgb(247,66,49)",
                          // },
                        }}
                      >
                        {data?.total_coffee_sold || 0}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      minWidth: 290,
                      height: 150,
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      ":hover": {
                        borderColor: "rgb(247,66,49)",
                      },
                    }}
                  >
                    <Title color="#9FA2B4">Free Items</Title>
                    <Box
                      sx={{
                        dispaly: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="h4"
                        sx={{ fontWeight: "bold" }}
                      >
                        {data?.total_free_coffee || 0}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      minWidth: 290,
                      height: 150,
                      borderTop: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      borderBottom: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      ":hover": {
                        borderColor: "rgb(247,66,49)",
                      },
                    }}
                  >
                    <Title color="#9FA2B4">Total Customer</Title>
                    <Box
                      sx={{
                        dispaly: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="h4"
                        sx={{ fontWeight: "bold" }}
                      >
                        {data?.total_customer || 0}
                      </Typography>
                      <ListItemIcon>
                        <img
                          src={customerIcon}
                          style={{ height: 20, width: 20 }}
                          alt=""
                        />
                      </ListItemIcon>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              {/* Recent Sales */}
              <Grid
                item
                xs={12}
                sx={{ mt: 3, display: "flex", flexDirection: "column" }}
              >
                <SalesData />
              </Grid>

              {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}
