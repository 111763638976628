import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableContainer,
  Avatar,
  Snackbar,
} from "@mui/material";

import axios from "../api/axios";
import { API_URL } from "../utils/api";

function preventDefault(event) {
  event.preventDefault();
}

export default function SalesData() {
  const [data, setData] = useState([]);
  const [paginatedInfo, setPaginatedInfo] = useState(null);

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(8);

  const [page, setPage] = React.useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine === false) {
        setIsToastOpen(true);
      } else {
        setIsToastOpen(false);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  const handleChangePage = async (event, newPage) => {
    if (isOnline) {
      setPage(newPage);

      const offsetData = Number(newPage) * limit;
      let apiUrl = `merchant/branches/sales/${selectedFilter}?limit=${limit}&offset=${offsetData}`;

      await axios(apiUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          setData(res.data.data?.data);
          setPaginatedInfo(res.data.data);
        })
        .catch((error) => console.error(error));
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (isOnline) {
      getSalesData(selectedFilter);
    }
  }, [isOnline]);

  const getSalesData = async (param) => {
    await axios(API_URL + `merchant/branches/sales/${param}?limit=8&offset=0`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        setData(res.data?.data?.data);
        setPaginatedInfo(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  const setAllCoffee = (param) => {
    if (isOnline) {
      setLimit(8);
      setSelectedFilter(param);
      getSalesData(param);
      setPage(0);
      setOffset(1);
    }
  };

  return (
    <React.Fragment>
      {isOnline ? null : (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isToastOpen}
          autoHideDuration={4000}
          onClose={() => setIsToastOpen(false)}
          message="No Internet Connection"
        />
      )}
      <TableContainer
        sx={{
          borderTop: "1px solid #e0e0e0",
          borderRight: "1px solid #e0e0e0",
          borderLeft: "1px solid #e0e0e0",
          borderBottom: "1px solid #e0e0e0",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              sx={{ mt: 1, mx: 2, flex: "1 1 100%" }}
              variant="h4"
              id="tableTitle"
              component="div"
            >
              Sales
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
            }}
          >
            <Typography
              sx={
                selectedFilter === "all"
                  ? {
                      fontSize: "15px",
                      mx: 3,
                      padding: 1,
                      paddingX: 2,
                      borderRadius: 2,
                      background: " rgb(247, 66, 49, 0.3)",
                      color: "#F74231",
                      cursor: "pointer",
                    }
                  : {
                      fontSize: "15px",
                      mx: 3,
                      padding: 1,
                      paddingX: 2,
                      cursor: "pointer",
                    }
              }
              variant="h6"
              id="tableTitle"
              component="div"
              onClick={() => setAllCoffee("all")}
            >
              All
            </Typography>
            <Typography
              sx={
                selectedFilter === "sold"
                  ? {
                      fontSize: "15px",
                      mx: 3,
                      padding: 1,
                      paddingX: 2,
                      borderRadius: 2,
                      background: " rgb(247, 66, 49, 0.3)",
                      color: "#F74231",
                      cursor: "pointer",
                    }
                  : {
                      fontSize: "15px",
                      mx: 3,
                      padding: 1,
                      paddingX: 2,
                      cursor: "pointer",
                    }
              }
              variant="h6"
              id="tableTitle"
              component="div"
              onClick={() => setAllCoffee("sold")}
            >
              Sold
            </Typography>
            <Typography
              sx={
                selectedFilter === "free"
                  ? {
                      fontSize: "15px",
                      mx: 3,
                      padding: 1,
                      paddingX: 2,
                      borderRadius: 2,
                      background: " rgb(247, 66, 49, 0.3)",
                      color: "#F74231",
                      cursor: "pointer",
                    }
                  : {
                      fontSize: "15px",
                      mx: 3,
                      padding: 1,
                      paddingX: 2,
                      cursor: "pointer",
                    }
              }
              variant="h6"
              id="tableTitle"
              component="div"
              onClick={() => setAllCoffee("free")}
            >
              Free
            </Typography>
          </Box>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "rgb(149,158,176)" }}>
                Customer Name
              </TableCell>

              <TableCell sx={{ color: "rgb(149,158,176)" }}>Items</TableCell>
              <TableCell sx={{ color: "rgb(149,158,176)" }}>Date</TableCell>
              <TableCell sx={{ color: "rgb(149,158,176)" }}>Status</TableCell>
              <TableCell sx={{ color: "rgb(149,158,176)" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                  No Records found
                </TableCell>
              </TableRow>
            )}

            {(data || []).map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Avatar sx={{ width: 35, height: 35, fontSize: 14 }}>
                        {row?.initial}
                      </Avatar>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", ml: 2 }}
                      >
                        <Typography sx={{}}>{row?.User?.name}</Typography>
                        <Typography sx={{}}> {row?.email}</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontWeight: "800" }}>
                    {row?.total_coffees}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {/* {new Date(row?.created_at).getDate() +
                        " / " +
                        new Date(row?.created_at).getMonth() +
                        " / " +
                        new Date(row?.created_at).getFullYear()} */}
                      {new Date(row?.created_at).toLocaleString("default", {
                        month: "long",
                      }) +
                        " " +
                        new Date(row?.created_at).getDate() +
                        ", " +
                        new Date(row?.created_at).getFullYear()}
                    </Box>
                    <Box sx={{ fontSize: "12px", color: "rgb(149,158,176)" }}>
                      {new Date(row?.created_at).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={preventDefault}
                      sx={
                        row?.is_free_coffee === "Yes"
                          ? {
                              fontSize: "10px",
                              p: "5px",
                              borderRadius: "12px",
                              boxShadow: "none",
                              backgroundColor: "rgb(247,66,49)",
                              ":hover": {
                                bgcolor: "rgb(247,66,49)",
                              },
                            }
                          : {
                              fontSize: "10px",
                              p: "5px",
                              borderRadius: "12px",
                              boxShadow: "none",
                              backgroundColor: "#34A853",
                              ":hover": {
                                bgcolor: "#34A853",
                              },
                            }
                      }
                    >
                      {row?.is_free_coffee === "Yes" ? "FREE" : "SOLD"}
                    </Button>
                  </TableCell>

                  <TableCell>
                    {/* <MoreVertIcon color="disabled" /> */}
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ color: "#ffd380", fontSize: "9px" }}>
                        <span className="material-symbols-outlined">
                          edit_square
                        </span>
                      </Box>
                      <Box
                        sx={{ m: "3px", color: "#f17e74", fontSize: "10px" }}
                      >
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={paginatedInfo?.totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}
