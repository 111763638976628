import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

function Title(props) {
  return (
    <Typography
      component="h5"
      variant="h6"
      color={props.color ? props.color : "black"}
      gutterBottom
      mx={2}
      sx={{ ...props.sx, alignSelf: "center", fontFamily: "sans-serif" }}
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
